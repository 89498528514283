<template>
  <div class="page page-content">

    <div v-if="orders.length === 0" class="empty-dashboard">
      <v-img src="@/assets/thumbs_up.png" class="thumb-up" height="250" width="250"></v-img>
      <h1>Rien à faire par ici :)</h1>
    </div>
    <Block v-else :title="`Commandes à expédier le ${$formatDate(shippingDate, 'DD/MM/YYYY à HH:mm')}`"
           v-for="(shippingDate, i) in Object.keys(ordersByExpeditionDate)" v-bind:key="`shipping-${i}`">
      <template v-slot:header-action>
        <span v-if="isLate(shippingDate)" class="error--text" style="margin-right: 1em">En retard</span>
        <span v-if="errorMessage" class="error--text" style="margin-right: 1em">{{errorMessage}}</span>
        <v-progress-circular indeterminate v-if="actionLoading" />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" text v-bind="attrs" v-on="on">
              Actions <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions"
                         :key="index" link @click="item.execute">
              <v-list-item-action>{{ item.text }}</v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <div>
        <div>Nombre de colis : {{ ordersByExpeditionDate[shippingDate].length }}</div>
        <div>Poids total : {{ totalWeight(ordersByExpeditionDate[shippingDate]) }}kg</div>
      </div>
    </Block>

  </div>
</template>

<script>
import {mapState} from "vuex";
import Block from "../components/SimpleBlock.vue";
import {groupBy, orderBy, reduce} from "lodash";
import moment from "moment";

export default {
  name: "ExpeditionsPage",
  components: {Block},
  data() {
    return {
      errorMessage: null,
      actionLoading: false,
      actions: [{
        text: "Copier les tracking numbers",
        execute: this.copyTrackingNumbers
      },{
        text: "Imprimer les étiquette de transport",
        execute: this.printTransportLabels
      }]
    }
  },
  computed: {
    ...mapState({
      orders(state) {
        return state.orders.orders.filter(order => {
          return order.status === 'TO_DISPATCH'
        })
      },
    }),
    ordersByExpeditionDate() {
      let dictionary = groupBy(orderBy(this.orders, o => o.delivery.expeditionDate), o => o.delivery.expeditionDate);
      return dictionary
    },
  },
  methods: {
    isLate(expeditionDate) {
      return moment(expeditionDate).isBefore(moment())
    },
    totalWeight(orders) {

      let products = orders.map(o => reduce(o.items, (a, b) => {
        return a === 0
            ? b.product.weight * b.quantity
            : a.product.weight * a.quantity + b.product.weight * b.quantity
      }, 0))

      return reduce(products, (a, b) => a + b, 0)
    },
    copyTrackingNumbers() {

      let trackingNumbers = this.orders
          .filter(o => o.deliveryType !== 'DRIVE')
          .map(order => order.delivery.trackingNumber)
          .join(";")

      navigator.clipboard.writeText(trackingNumbers)
      this.successMessage = "Copié dans le presse papier"

    },
    async printTransportLabels() {

      try {
        this.actionLoading = true
        await this.$store.dispatch("orders/printTransportLabelBatch", this.orders.map(o => ({
          orderId: o.orderId,
          userId: o.userId
        })))

      } catch (e) {
        this.errorMessage = "Impossible d'imprimer les étiquettes"
      } finally {
        this.actionLoading = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.empty-dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
